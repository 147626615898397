import { Router } from '@vaadin/router';

export class RouterService {
  private static instance: RouterService;
  public router: Router;

  private constructor(elementId: string) {
    this.router = new Router(document.getElementById(elementId));
  }

  static init(elementId: string) {
    RouterService.instance = new RouterService(elementId);
  }

  static getInstance() {
    if (!RouterService.instance) throw 'RouterService not initialized. Call init from app-root first.';
    return RouterService.instance;
  }
}
